<template>
  <el-main>
    <div style="margin-bottom: 20px">
      <el-button type="primary" size="small" @click="$router.push('/service/beautyAddService')">添加服务</el-button>
    </div>
    <!-- 搜索表单 -->
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="服务名称：">
        <el-input v-model="ruleForm.service_name" size="small" placeholder="请输入服务名称"></el-input>
      </el-form-item>
      <el-form-item label="服务分类：">
        <el-cascader
          v-model="ruleForm.classify_id"
          :props="{ value: 'id', label: 'name', children: '_child' }"
          placeholder="请选择或搜索"
          filterable
          :options="serviceClassify"
          size="small"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="是否上架：">
        <el-select size="small" v-model="ruleForm.is_putaway">
          <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getServiceList(1)">搜索</el-button>
        <el-button size="small">导出</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 渲染表格 -->
    <el-table :data="serviceList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column align="center" width="100px">
        <template #header>
          <el-checkbox v-model="allCheck" @change="allCheckChange" label="全选"></el-checkbox>
        </template>
        <template v-slot="{ row }">
          <el-checkbox @change="serviceCheckChange(row)" v-model="row.checkFlag"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="排序" align="center" width="100px">
        <template v-slot="{ row }">
          <el-input class="borderNone" @change="changeServiceData(row, 'sort')" size="small" type="number" v-model="row.sort"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="服务ID" align="center" width="80px"></el-table-column>
      <el-table-column label="服务主图" align="center" width="80px">
        <template v-slot="{ row }">
          <el-image style="width: 60px; height: 60px" :src="row.service_cover_picture ? row.service_cover_picture : require('@/assets/default-img.png')"></el-image>
        
        </template>
      </el-table-column>
      <el-table-column prop="service_name" label="服务名称" align="center"></el-table-column>
      <el-table-column prop="classify_name" label="服务分类" align="center"></el-table-column>
      <el-table-column label="商品价格" align="center" width="150px">
        <template v-slot="{ row }">
          <el-input class="borderNone" @change="changeServiceData(row, 'service_price')" size="small" type="number" v-model="row.service_price"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="销量" align="center" width="100px">
        <template v-slot="{ row }">
          {{ Number(row.virtual_sales) + Number(row.sales) }}
        </template>
      </el-table-column>
      <el-table-column label="上/下架" align="center" width="80px">
        <template v-slot="{ row }">
          <el-button @click="changeServiceData(row, 'is_putaway')" :type="!row.is_putaway ? 'primary' : ''" size="small">{{ row.is_putaway ? '下架' : '上架' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="200px">
        <template v-slot="{ row }">
          <el-button type="text" @click="$router.push('/service/serviceEdit?id=' + row.id)">编辑</el-button>
          <el-button type="text" @click="deleteService([row.id])">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="serviceTotal" :page="servicePage" :pageNum="serviceRows" @updatePageNum="updateServiceData">
      <template #batch>
        <div>
          <el-checkbox v-model="allCheck" @change="allCheckChange" label="全选"></el-checkbox>
          <el-select style="margin: 0 20px" size="small" v-model="allSelect">
            <el-option v-for="item in batchList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-button size="small" @click="manyOperation">确定</el-button>
        </div>
      </template>
    </Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      ruleForm: {
        classify_id: 0,
        service_name: '',
        is_putaway: 0,
      },
      // 搜索时使用的分类列表
      serviceClassify: [],
      statusList: [
        { id: 0, name: '全部' },
        { id: 2, name: '上架' },
        { id: 1, name: '下架' },
      ],
      serviceList: [],
      chooseServiceList: [],
      servicePage: 1,
      serviceRows: 10,
      serviceTotal: 0,
      allCheck: false,
      allSelect: null,
      batchList: [
        { id: 1, name: '上架' },
        { id: 2, name: '下架' },
        { id: 3, name: '删除' },
      ],
    };
  },
  created() {
    this.getServiceClassify();
    this.getServiceList();
  },
  methods: {
    // 清空列表的搜索条件
    cancelSearch() {
      this.ruleForm = {
        classify_id: 0,
        service_name: '',
        is_putaway: 0,
      };
      this.getServiceList(1);
    },
    // 服务列表的分页操作
    updateServiceData(val, status) {
      if (status == 0) {
        this.serviceRows = val;
      } else {
        this.servicePage = val;
      }
      this.getServiceList();
    },
    // 获取服务分类
    getServiceClassify() {
      this.$axios.post(this.$api.service.ServiceClassify).then(res => {
        if (res.code === 0) {
          this.serviceClassify = res.result;
        }
      });
    },
    // 查询服务列表
    getServiceList(style) {
      if (style) this.servicePage = 1;
      let obj = {
        page: this.servicePage,
        rows: this.serviceRows,
      };
      if (this.ruleForm.service_name) {
        obj.service_name = this.ruleForm.service_name;
      }
      if (this.ruleForm.classify_id) {
        obj.classify_id = this.ruleForm.classify_id[this.ruleForm.classify_id.length - 1];
      }
      if (this.ruleForm.is_putaway) {
        obj.is_putaway = this.ruleForm.is_putaway;
      }
      this.$axios.post(this.$api.service.serviceList, obj).then(res => {
        if (res.code === 0) {
          this.serviceList = res.result.list;
          this.serviceList.map(item => {
            this.$set(item, 'checkFlag', false);
          });
          // 清空选中状态
          this.chooseServiceList = [];
          this.allCheck = false;
          this.allSelect = null;
          this.serviceTotal = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 更改服务数据
    changeServiceData(data, key, is_many) {
      if (key === 'is_putaway') {
        let is_putaway = data.is_putaway ? 0 : 1;
        let ids = [data.id];
        this.$confirm(`请确定是否${data.is_putaway ? '下架' : '上架'}该服务！！！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            return this.changeStatus(ids, is_putaway);
          })
          .then(res => {
            if (res.code == 0) {
              data.is_putaway = is_putaway;
              this.$message.success('修改成功');
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch(() => {});
      } else {
        let obj = {
          id: data.id,
        };
        if (key === 'sort') obj.sort = data.sort;
        if (key === 'service_price') obj.service_price = data.service_price;
        this.$axios.post(this.$api.service.editInfo, obj).then(res => {
          if (res.code !== 0) {
            this.$message.error(res.msg);
            this.getServiceList();
          }
        });
      }
    },
    // 服务上下架
    changeStatus(ids = [], is_putaway) {
      return this.$axios.post(this.$api.service.editStatus, {
        id: ids,
        is_putaway,
      });
    },
    // 删除服务
    deleteService(ids) {
      this.$confirm(`此操作将永久删除选中的服务！！！`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          return await this.$axios.post(this.$api.service.delService, {
            id: ids,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getServiceList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    // 操作全选
    allCheckChange() {
      if (this.allCheck) {
        this.serviceList.map(item => {
          item.checkFlag = true;
          this.chooseServiceList.push(item.id);
        });
      } else {
        this.serviceList.map(item => {
          item.checkFlag = false;
          this.chooseServiceList = [];
        });
      }
    },
    serviceCheckChange(data) {
      if (!data.checkFlag) {
        this.allCheck = false;
        let index = this.chooseServiceList.findIndex(item => item == data.id);
        this.chooseServiceList.splice(index, 1);
      } else {
        this.chooseServiceList.push(data.id);
        if (this.chooseServiceList.length === this.serviceList.length) this.allCheck = true;
      }
    },
    // 批量操作
    manyOperation() {
      if (this.allSelect === null) {
        this.$message.warning('请选择要进行的操作');
        return;
      }
      if (!this.chooseServiceList.length) {
        this.$message.warning('请选择要进行的操作的服务');
        return;
      }
      if (this.allSelect == 3) {
        this.deleteService(this.chooseServiceList);
      } else {
        let is_putaway = this.allSelect === 1 ? 1 : this.allSelect === 2 ? 0 : '';
        this.$confirm(`此操作将批量${this.allSelect === 1 ? '上架' : this.allSelect === 2 ? '下架' : ''}服务！！！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            return this.changeStatus(this.chooseServiceList, is_putaway);
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('批量操作成功');
              // 清空选中状态
              this.getServiceList();
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .el-table {
    margin-top: 20px;
  }
  .addClassification {
    .classificationItem {
      display: flex;
      align-items: center;
      .classNameTitle {
        white-space: nowrap;
      }
    }
  }
  .btnBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .borderNone {
    /deep/ .el-input__inner {
      border-color: transparent;
    }
  }
}
</style>
